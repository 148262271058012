import { defineComponent, onBeforeUnmount, onMounted, onUpdated, ref, } from 'vue';
export default defineComponent({
    name: 'TwoColumn',
    setup() {
        const columnLeft = ref(null);
        const columnRight = ref(null);
        const mediaIsEnd = ref(false);
        const isSticky = ref(false);
        const checkColumnForVideoComponentHandler = (column) => {
            const videoComponentList = column.value?.querySelectorAll('.video-component');
            if (videoComponentList?.length)
                column.value.classList.add('column--willChangeAuto');
        };
        const resizeHandler = () => {
            if (!columnLeft.value || !columnRight.value)
                return;
            let sizeDifference = 1;
            if (columnLeft.value.children.length === 1) {
                sizeDifference =
                    columnLeft.value.children[0].getBoundingClientRect()
                        .height /
                        columnRight.value.getBoundingClientRect().height;
            }
            else if (columnRight.value.children.length === 1) {
                sizeDifference =
                    columnLeft.value.getBoundingClientRect().height /
                        columnRight.value.children[0].getBoundingClientRect()
                            .height;
            }
            isSticky.value = sizeDifference >= 1.5 || sizeDifference <= 0.5;
        };
        const mediaCheckHandler = () => {
            if (!columnLeft.value || !columnRight.value)
                return;
            const leftContent = columnLeft.value.children;
            const rightContent = columnRight.value.children;
            const mediaClasses = [
                'image-with-headline',
                'videoWithHeadline',
                'image-with-icon',
                'stage-carousel',
            ];
            if (rightContent.length === 1 &&
                mediaClasses.some((m) => rightContent[0].classList.contains(m)) &&
                !mediaClasses.some((m) => leftContent[0].classList.contains(m))) {
                mediaIsEnd.value = true;
            }
            (mediaIsEnd.value
                ? columnRight.value
                : columnLeft.value)?.classList.add('fade-from-top');
        };
        onMounted(() => {
            // Somehow the initialization takes more time in FS than in Storybook
            setTimeout(() => resizeHandler(), 400);
            setTimeout(() => mediaCheckHandler(), 500);
            setTimeout(() => {
                checkColumnForVideoComponentHandler(columnLeft);
                checkColumnForVideoComponentHandler(columnRight);
            }, 500);
        });
        onUpdated(() => {
            resizeHandler();
            mediaCheckHandler();
        });
        window.addEventListener('resize', resizeHandler);
        onBeforeUnmount(() => window.removeEventListener('resize', resizeHandler));
        return { mediaIsEnd, isSticky, columnLeft, columnRight };
    },
});
